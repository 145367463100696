import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-invoice-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class PortalInvoiceListComponent {
  dataInvoices: any;
  constructor() {
    this.dataInvoices = [
      {
        _id: 1046567,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2022/011986',
        rnr: 'R03/2022/011986',
        rtotal: 124.7,
        rgesamt: 164.89,
        rgesamtnf: 0.0,
        rgnetto: 154.11,
        rgnettonf: 0.0,
        psum: 42.87,
        psumnf: 0.0,
        psumex: 40.07,
        psumexnf: 0.0,
        steuer7: 8.16,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 124.7,
        rbetragnf: 0.0,
        srgesamt: 123.71,
        srgesamtnf: 0.0,
        eksum: 78.71,
        eksumnf: 0.0,
        vkwert: 229.86,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-01-07',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 64.67,
        avqnf: 0.0,
        avqtotal: 64.67,
        ca: false,
        geliefert: 229.86,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -118.78,
        provproz: 26,
        skontproz: 0,
        nsum: 116.54,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1047522,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012107',
        rnr: 'R03/2023/012107',
        rtotal: 102.64,
        rgesamt: 124.94,
        rgesamtnf: 0.0,
        rgnetto: 116.77,
        rgnettonf: 0.0,
        psum: 24.98,
        psumnf: 0.0,
        psumex: 23.35,
        psumexnf: 0.0,
        steuer7: 6.72,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 102.64,
        rbetragnf: 0.0,
        srgesamt: 93.71,
        srgesamtnf: 0.0,
        eksum: 42.73,
        eksumnf: 0.0,
        vkwert: 139.93,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-01-14',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 89.29,
        avqnf: 0.0,
        avqtotal: 89.29,
        ca: false,
        geliefert: 139.93,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -66.08,
        provproz: 20,
        skontproz: 0,
        nsum: 95.92,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1048572,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012175',
        rnr: 'R03/2023/012175',
        rtotal: 127.68,
        rgesamt: 168.9,
        rgesamtnf: 0.0,
        rgnetto: 157.86,
        rgnettonf: 0.0,
        psum: 43.9,
        psumnf: 0.0,
        psumex: 41.04,
        psumexnf: 0.0,
        steuer7: 8.36,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 127.68,
        rbetragnf: 0.0,
        srgesamt: 126.69,
        srgesamtnf: 0.0,
        eksum: 55.7,
        eksumnf: 0.0,
        vkwert: 177.89,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-01-21',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 94.48,
        avqnf: 0.0,
        avqtotal: 94.48,
        ca: false,
        geliefert: 177.89,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -96.74,
        provproz: 26,
        skontproz: 0,
        nsum: 119.32,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1057314,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012799',
        rnr: 'R03/2023/012799',
        rtotal: 152.83,
        rgesamt: 202.9,
        rgesamtnf: 0.0,
        rgnetto: 189.64,
        rgnettonf: 0.0,
        psum: 52.75,
        psumnf: 0.0,
        psumex: 49.31,
        psumexnf: 0.0,
        steuer7: 10.0,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 152.83,
        rbetragnf: 0.0,
        srgesamt: 152.19,
        srgesamtnf: 0.0,
        eksum: 60.41,
        eksumnf: 0.0,
        vkwert: 202.9,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-03-25',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 202.9,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -109.72,
        provproz: 26,
        skontproz: 0,
        nsum: 142.83,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1058271,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012869',
        rnr: 'R03/2023/012869',
        rtotal: 55.94,
        rgesamt: 64.95,
        rgesamtnf: 0.0,
        rgnetto: 60.71,
        rgnettonf: 0.0,
        psum: 11.69,
        psumnf: 0.0,
        psumex: 10.93,
        psumexnf: 0.0,
        steuer7: 3.66,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 55.94,
        rbetragnf: 0.0,
        srgesamt: 48.74,
        srgesamtnf: 0.0,
        eksum: 60.13,
        eksumnf: 0.0,
        vkwert: 212.85,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-04-01',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 24.59,
        avqnf: 0.0,
        avqtotal: 24.59,
        ca: false,
        geliefert: 212.85,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -71.06,
        provproz: 18,
        skontproz: 0,
        nsum: 52.28,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1059500,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012938',
        rnr: 'R03/2023/012938',
        rtotal: 118.03,
        rgesamt: 155.89,
        rgesamtnf: 0.0,
        rgnetto: 145.68,
        rgnettonf: 0.0,
        psum: 40.54,
        psumnf: 0.0,
        psumex: 37.88,
        psumexnf: 0.0,
        steuer7: 7.73,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 118.03,
        rbetragnf: 0.0,
        srgesamt: 116.94,
        srgesamtnf: 0.0,
        eksum: 66.67,
        eksumnf: 0.0,
        vkwert: 205.86,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-04-05',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 87.58,
        avqnf: 0.0,
        avqtotal: 87.58,
        ca: false,
        geliefert: 195.87,
        bas: 9.99,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -104.55,
        provproz: 26,
        skontproz: 0,
        nsum: 110.3,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1060561,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012998',
        rnr: 'R03/2023/012998',
        rtotal: 478.24,
        rgesamt: 642.65,
        rgesamtnf: 0.0,
        rgnetto: 600.61,
        rgnettonf: 0.0,
        psum: 167.09,
        psumnf: 0.0,
        psumex: 156.16,
        psumexnf: 0.0,
        steuer7: 31.29,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 478.24,
        rbetragnf: 0.0,
        srgesamt: 482.01,
        srgesamtnf: 0.0,
        eksum: 207.02,
        eksumnf: 0.0,
        vkwert: 723.62,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-04-15',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 88.81,
        avqnf: 0.0,
        avqtotal: 88.81,
        ca: false,
        geliefert: 673.65,
        bas: 49.97,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -363.18,
        provproz: 26,
        skontproz: 0,
        nsum: 446.95,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1049534,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012243',
        rnr: 'R03/2023/012243',
        rtotal: 10.23,
        rgesamt: 8.99,
        rgesamtnf: 0.0,
        rgnetto: 8.4,
        rgnettonf: 0.0,
        psum: 1.44,
        psumnf: 0.0,
        psumex: 1.34,
        psumexnf: 0.0,
        steuer7: 0.67,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 10.23,
        rbetragnf: 0.0,
        srgesamt: 6.74,
        srgesamtnf: 0.0,
        eksum: 67.65,
        eksumnf: 0.0,
        vkwert: 190.87,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-01-28',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 6.16,
        avqnf: 0.0,
        avqtotal: 6.16,
        ca: false,
        geliefert: 190.87,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -68.99,
        provproz: 16,
        skontproz: 0,
        nsum: 9.56,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1050340,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012317',
        rnr: 'R03/2023/012317',
        rtotal: 116.21,
        rgesamt: 141.91,
        rgesamtnf: 0.0,
        rgnetto: 132.63,
        rgnettonf: 0.0,
        psum: 28.38,
        psumnf: 0.0,
        psumex: 26.53,
        psumexnf: 0.0,
        steuer7: 7.61,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 116.21,
        rbetragnf: 0.0,
        srgesamt: 106.45,
        srgesamtnf: 0.0,
        eksum: 46.05,
        eksumnf: 0.0,
        vkwert: 198.86,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-02-04',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 78.9,
        avqnf: 0.0,
        avqtotal: 78.9,
        ca: false,
        geliefert: 153.89,
        bas: 44.97,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -72.58,
        provproz: 20,
        skontproz: 0,
        nsum: 108.6,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1050974,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012384',
        rnr: 'R03/2023/012384',
        rtotal: 160.21,
        rgesamt: 212.87,
        rgesamtnf: 0.0,
        rgnetto: 198.95,
        rgnettonf: 0.0,
        psum: 55.34,
        psumnf: 0.0,
        psumex: 51.73,
        psumexnf: 0.0,
        steuer7: 10.49,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 160.21,
        rbetragnf: 0.0,
        srgesamt: 159.67,
        srgesamtnf: 0.0,
        eksum: 66.56,
        eksumnf: 0.0,
        vkwert: 252.85,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-02-11',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 84.19,
        avqnf: 0.0,
        avqtotal: 84.19,
        ca: false,
        geliefert: 222.87,
        bas: 29.98,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -118.29,
        provproz: 26,
        skontproz: 0,
        nsum: 149.72,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1052545,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012446',
        rnr: 'R03/2023/012446',
        rtotal: 339.18,
        rgesamt: 454.72,
        rgesamtnf: 0.0,
        rgnetto: 424.99,
        rgnettonf: 0.0,
        psum: 118.22,
        psumnf: 0.0,
        psumex: 110.5,
        psumexnf: 0.0,
        steuer7: 22.19,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 339.18,
        rbetragnf: 0.0,
        srgesamt: 341.09,
        srgesamtnf: 0.0,
        eksum: 173.83,
        eksumnf: 0.0,
        vkwert: 598.64,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-02-18',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 75.96,
        avqnf: 0.0,
        avqtotal: 75.96,
        ca: false,
        geliefert: 558.66,
        bas: 39.98,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -284.33,
        provproz: 26,
        skontproz: 0,
        nsum: 316.99,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1069493,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/010125',
        rnr: 'R03/2023/010125',
        rtotal: 171.32,
        rgesamt: 227.87,
        rgesamtnf: 0.0,
        rgnetto: 212.98,
        rgnettonf: 0.0,
        psum: 59.23,
        psumnf: 0.0,
        psumex: 55.37,
        psumexnf: 0.0,
        steuer7: 11.21,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 171.32,
        rbetragnf: 0.0,
        srgesamt: 170.92,
        srgesamtnf: 0.0,
        eksum: 67.58,
        eksumnf: 0.0,
        vkwert: 242.86,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-06-17',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 93.83,
        avqnf: 0.0,
        avqtotal: 93.83,
        ca: false,
        geliefert: 242.86,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -122.95,
        provproz: 26,
        skontproz: 0,
        nsum: 160.11,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1070604,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/010202',
        rnr: 'R03/2023/010202',
        rtotal: 127.69,
        rgesamt: 168.91,
        rgesamtnf: 0.0,
        rgnetto: 157.88,
        rgnettonf: 0.0,
        psum: 43.9,
        psumnf: 0.0,
        psumex: 41.05,
        psumexnf: 0.0,
        steuer7: 8.36,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 127.69,
        rbetragnf: 0.0,
        srgesamt: 126.7,
        srgesamtnf: 0.0,
        eksum: 56.02,
        eksumnf: 0.0,
        vkwert: 201.89,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-06-24',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 83.66,
        avqnf: 0.0,
        avqtotal: 83.66,
        ca: false,
        geliefert: 201.89,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -97.07,
        provproz: 26,
        skontproz: 0,
        nsum: 119.33,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1071404,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010272',
        rnr: 'R03/2023/010272',
        rtotal: 115.43,
        rgesamt: 140.91,
        rgesamtnf: 0.0,
        rgnetto: 131.71,
        rgnettonf: 0.0,
        psum: 28.16,
        psumnf: 0.0,
        psumex: 26.34,
        psumexnf: 0.0,
        steuer7: 7.56,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 115.43,
        rbetragnf: 0.0,
        srgesamt: 105.72,
        srgesamtnf: 0.0,
        eksum: 62.52,
        eksumnf: 0.0,
        vkwert: 221.87,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-01',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 59.9,
        avqnf: 0.0,
        avqtotal: 59.9,
        ca: false,
        geliefert: 221.87,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -88.86,
        provproz: 20,
        skontproz: 0,
        nsum: 107.87,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1072509,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P10/2023/047280',
        rnr: 'R10/2023/047280',
        rtotal: 84.64,
        rgesamt: 99.94,
        rgesamtnf: 0.0,
        rgnetto: 93.42,
        rgnettonf: 0.0,
        psum: 17.98,
        psumnf: 0.0,
        psumex: 16.82,
        psumexnf: 0.0,
        steuer7: 5.54,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 84.64,
        rbetragnf: 0.0,
        srgesamt: 74.96,
        srgesamtnf: 0.0,
        eksum: 70.9,
        eksumnf: 0.0,
        vkwert: 245.85,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-08',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 39.82,
        avqnf: 0.0,
        avqtotal: 39.82,
        ca: false,
        geliefert: 245.85,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -87.72,
        provproz: 18,
        skontproz: 0,
        nsum: 79.1,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1061570,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013074',
        rnr: 'R03/2023/013074',
        rtotal: 121.0,
        rgesamt: 159.89,
        rgesamtnf: 0.0,
        rgnetto: 149.43,
        rgnettonf: 0.0,
        psum: 41.57,
        psumnf: 0.0,
        psumex: 38.85,
        psumexnf: 0.0,
        steuer7: 7.92,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 121.0,
        rbetragnf: 0.0,
        srgesamt: 119.93,
        srgesamtnf: 0.0,
        eksum: 49.85,
        eksumnf: 0.0,
        vkwert: 182.88,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-04-22',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 87.43,
        avqnf: 0.0,
        avqtotal: 87.43,
        ca: false,
        geliefert: 182.88,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -88.7,
        provproz: 26,
        skontproz: 0,
        nsum: 113.08,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1062318,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013142',
        rnr: 'R03/2023/013142',
        rtotal: 119.55,
        rgesamt: 157.9,
        rgesamtnf: 0.0,
        rgnetto: 147.59,
        rgnettonf: 0.0,
        psum: 41.03,
        psumnf: 0.0,
        psumex: 38.37,
        psumexnf: 0.0,
        steuer7: 7.83,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 119.55,
        rbetragnf: 0.0,
        srgesamt: 118.46,
        srgesamtnf: 0.0,
        eksum: 66.54,
        eksumnf: 0.0,
        vkwert: 199.88,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-04-29',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 84.11,
        avqnf: 0.0,
        avqtotal: 84.11,
        ca: false,
        geliefert: 199.88,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -104.91,
        provproz: 26,
        skontproz: 0,
        nsum: 111.72,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1063562,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013211',
        rnr: 'R03/2023/013211',
        rtotal: 174.28,
        rgesamt: 231.85,
        rgesamtnf: 0.0,
        rgnetto: 216.71,
        rgnettonf: 0.0,
        psum: 60.25,
        psumnf: 0.0,
        psumex: 56.34,
        psumexnf: 0.0,
        steuer7: 11.41,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 174.28,
        rbetragnf: 0.0,
        srgesamt: 173.94,
        srgesamtnf: 0.0,
        eksum: 66.44,
        eksumnf: 0.0,
        vkwert: 231.85,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-05-06',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 216.86,
        bas: 14.99,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -122.78,
        provproz: 26,
        skontproz: 0,
        nsum: 162.87,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1064262,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013279',
        rnr: 'R03/2023/013279',
        rtotal: 124.73,
        rgesamt: 164.92,
        rgesamtnf: 0.0,
        rgnetto: 154.15,
        rgnettonf: 0.0,
        psum: 42.87,
        psumnf: 0.0,
        psumex: 40.08,
        psumexnf: 0.0,
        steuer7: 8.16,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 124.73,
        rbetragnf: 0.0,
        srgesamt: 123.7,
        srgesamtnf: 0.0,
        eksum: 52.69,
        eksumnf: 0.0,
        vkwert: 184.9,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-05-10',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 89.19,
        avqnf: 0.0,
        avqtotal: 89.19,
        ca: false,
        geliefert: 184.9,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -92.77,
        provproz: 26,
        skontproz: 0,
        nsum: 116.57,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1065521,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013343',
        rnr: 'R03/2023/013343',
        rtotal: 1190.4,
        rgesamt: 1605.08,
        rgesamtnf: 0.0,
        rgnetto: 1500.03,
        rgnettonf: 0.0,
        psum: 417.36,
        psumnf: 0.0,
        psumex: 390.01,
        psumexnf: 0.0,
        steuer7: 77.88,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 1190.4,
        rbetragnf: 0.0,
        srgesamt: 1203.89,
        srgesamtnf: 0.0,
        eksum: 475.68,
        eksumnf: 0.0,
        vkwert: 1620.07,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-05-17',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 99.07,
        avqnf: 0.0,
        avqtotal: 99.07,
        ca: false,
        geliefert: 1620.07,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -865.69,
        provproz: 26,
        skontproz: 0,
        nsum: 1112.52,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1066487,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013489',
        rnr: 'R03/2023/013489',
        rtotal: 182.41,
        rgesamt: 242.86,
        rgesamtnf: 0.0,
        rgnetto: 226.99,
        rgnettonf: 0.0,
        psum: 63.13,
        psumnf: 0.0,
        psumex: 59.02,
        psumexnf: 0.0,
        steuer7: 11.94,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 182.41,
        rbetragnf: 0.0,
        srgesamt: 182.17,
        srgesamtnf: 0.0,
        eksum: 70.16,
        eksumnf: 0.0,
        vkwert: 242.86,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-05-27',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 242.86,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -129.18,
        provproz: 26,
        skontproz: 0,
        nsum: 170.47,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1067378,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/013488',
        rnr: 'R03/2023/013488',
        rtotal: 191.28,
        rgesamt: 254.85,
        rgesamtnf: 0.0,
        rgnetto: 238.19,
        rgnettonf: 0.0,
        psum: 66.25,
        psumnf: 0.0,
        psumex: 61.93,
        psumexnf: 0.0,
        steuer7: 12.52,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 191.28,
        rbetragnf: 0.0,
        srgesamt: 191.16,
        srgesamtnf: 0.0,
        eksum: 74.2,
        eksumnf: 0.0,
        vkwert: 254.85,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-06-03',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 254.85,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -136.13,
        provproz: 26,
        skontproz: 0,
        nsum: 178.76,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1068420,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/010067',
        rnr: 'R03/2023/010067',
        rtotal: 93.05,
        rgesamt: 112.94,
        rgesamtnf: 0.0,
        rgnetto: 105.57,
        rgnettonf: 0.0,
        psum: 22.57,
        psumnf: 0.0,
        psumex: 21.11,
        psumexnf: 0.0,
        steuer7: 6.09,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 93.05,
        rbetragnf: 0.0,
        srgesamt: 84.71,
        srgesamtnf: 0.0,
        eksum: 62.93,
        eksumnf: 0.0,
        vkwert: 217.87,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-06-07',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 51.84,
        avqnf: 0.0,
        avqtotal: 51.84,
        ca: false,
        geliefert: 217.87,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -84.04,
        provproz: 20,
        skontproz: 0,
        nsum: 86.96,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1053536,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012520',
        rnr: 'R03/2023/012520',
        rtotal: 84.24,
        rgesamt: 101.95,
        rgesamtnf: 0.0,
        rgnetto: 95.28,
        rgnettonf: 0.0,
        psum: 20.39,
        psumnf: 0.0,
        psumex: 19.06,
        psumexnf: 0.0,
        steuer7: 5.52,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 84.24,
        rbetragnf: 0.0,
        srgesamt: 76.46,
        srgesamtnf: 0.0,
        eksum: 54.08,
        eksumnf: 0.0,
        vkwert: 189.9,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-02-25',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 53.69,
        avqnf: 0.0,
        avqtotal: 53.69,
        ca: false,
        geliefert: 169.91,
        bas: 19.99,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -73.14,
        provproz: 20,
        skontproz: 0,
        nsum: 78.72,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1054534,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012590',
        rnr: 'R03/2023/012590',
        rtotal: 123.25,
        rgesamt: 162.92,
        rgesamtnf: 0.0,
        rgnetto: 152.27,
        rgnettonf: 0.0,
        psum: 42.35,
        psumnf: 0.0,
        psumex: 39.59,
        psumexnf: 0.0,
        steuer7: 8.07,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 123.25,
        rbetragnf: 0.0,
        srgesamt: 122.2,
        srgesamtnf: 0.0,
        eksum: 62.03,
        eksumnf: 0.0,
        vkwert: 192.9,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-03-05',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 84.46,
        avqnf: 0.0,
        avqtotal: 84.46,
        ca: false,
        geliefert: 192.9,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -101.62,
        provproz: 26,
        skontproz: 0,
        nsum: 115.18,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1054699,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012659',
        rnr: 'R03/2023/012659',
        rtotal: 138.01,
        rgesamt: 182.88,
        rgesamtnf: 0.0,
        rgnetto: 170.92,
        rgnettonf: 0.0,
        psum: 47.55,
        psumnf: 0.0,
        psumex: 44.44,
        psumexnf: 0.0,
        steuer7: 9.03,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 138.01,
        rbetragnf: 0.0,
        srgesamt: 137.17,
        srgesamtnf: 0.0,
        eksum: 124.44,
        eksumnf: 0.0,
        vkwert: 409.77,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-03-09',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 44.63,
        avqnf: 0.0,
        avqtotal: 44.63,
        ca: false,
        geliefert: 409.77,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -168.88,
        provproz: 26,
        skontproz: 0,
        nsum: 128.98,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1056537,
        knr: 'J6173',
        kname: 'Fontaine                 ',
        lnr: 'P03/2023/012673',
        rnr: 'R03/2023/012673',
        rtotal: 60.86,
        rgesamt: 70.95,
        rgesamtnf: 0.0,
        rgnetto: 66.31,
        rgnettonf: 0.0,
        psum: 12.77,
        psumnf: 0.0,
        psumex: 11.94,
        psumexnf: 0.0,
        steuer7: 3.99,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 60.86,
        rbetragnf: 0.0,
        srgesamt: 53.22,
        srgesamtnf: 0.0,
        eksum: 53.55,
        eksumnf: 0.0,
        vkwert: 179.89,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-03-18',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 39.44,
        avqnf: 0.0,
        avqtotal: 39.44,
        ca: false,
        geliefert: 179.89,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -65.49,
        provproz: 18,
        skontproz: 0,
        nsum: 56.87,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1073581,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010411',
        rnr: 'R03/2023/010411',
        rtotal: 129.14,
        rgesamt: 170.91,
        rgesamtnf: 0.0,
        rgnetto: 159.72,
        rgnettonf: 0.0,
        psum: 44.45,
        psumnf: 0.0,
        psumex: 41.53,
        psumexnf: 0.0,
        steuer7: 8.45,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 129.14,
        rbetragnf: 0.0,
        srgesamt: 128.18,
        srgesamtnf: 0.0,
        eksum: 55.51,
        eksumnf: 0.0,
        vkwert: 243.87,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-15',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 74.18,
        avqnf: 0.0,
        avqtotal: 74.18,
        ca: false,
        geliefert: 193.9,
        bas: 49.97,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -97.04,
        provproz: 26,
        skontproz: 0,
        nsum: 120.69,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1074598,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010480',
        rnr: 'R03/2023/010480',
        rtotal: 123.99,
        rgesamt: 163.92,
        rgesamtnf: 0.0,
        rgnetto: 153.2,
        rgnettonf: 0.0,
        psum: 42.61,
        psumnf: 0.0,
        psumex: 39.83,
        psumexnf: 0.0,
        steuer7: 8.12,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 123.99,
        rbetragnf: 0.0,
        srgesamt: 122.93,
        srgesamtnf: 0.0,
        eksum: 51.41,
        eksumnf: 0.0,
        vkwert: 178.91,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-22',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 91.62,
        avqnf: 0.0,
        avqtotal: 91.62,
        ca: false,
        geliefert: 178.91,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -91.24,
        provproz: 26,
        skontproz: 0,
        nsum: 115.87,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1075503,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010547',
        rnr: 'R03/2023/010547',
        rtotal: 123.24,
        rgesamt: 162.9,
        rgesamtnf: 0.0,
        rgnetto: 152.26,
        rgnettonf: 0.0,
        psum: 42.34,
        psumnf: 0.0,
        psumex: 39.59,
        psumexnf: 0.0,
        steuer7: 8.07,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 123.24,
        rbetragnf: 0.0,
        srgesamt: 122.19,
        srgesamtnf: 0.0,
        eksum: 48.25,
        eksumnf: 0.0,
        vkwert: 162.9,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-29',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 162.9,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -87.84,
        provproz: 26,
        skontproz: 0,
        nsum: 115.17,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1076251,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010617',
        rnr: 'R03/2023/010617',
        rtotal: 83.0,
        rgesamt: 97.95,
        rgesamtnf: 0.0,
        rgnetto: 91.55,
        rgnettonf: 0.0,
        psum: 17.63,
        psumnf: 0.0,
        psumex: 16.48,
        psumexnf: 0.0,
        steuer7: 5.43,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 83.0,
        rbetragnf: 0.0,
        srgesamt: 73.46,
        srgesamtnf: 0.0,
        eksum: 38.5,
        eksumnf: 0.0,
        vkwert: 132.93,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-05',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 73.69,
        avqnf: 0.0,
        avqtotal: 73.69,
        ca: false,
        geliefert: 132.93,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -54.98,
        provproz: 18,
        skontproz: 0,
        nsum: 77.57,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1077576,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010686',
        rnr: 'R03/2023/010686',
        rtotal: 109.03,
        rgesamt: 132.93,
        rgesamtnf: 0.0,
        rgnetto: 124.24,
        rgnettonf: 0.0,
        psum: 26.58,
        psumnf: 0.0,
        psumex: 24.85,
        psumexnf: 0.0,
        steuer7: 7.14,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 109.03,
        rbetragnf: 0.0,
        srgesamt: 99.69,
        srgesamtnf: 0.0,
        eksum: 38.64,
        eksumnf: 0.0,
        vkwert: 132.93,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-12',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 132.93,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -63.49,
        provproz: 20,
        skontproz: 0,
        nsum: 101.89,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1078508,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010765',
        rnr: 'R03/2023/010765',
        rtotal: 130.63,
        rgesamt: 172.9,
        rgesamtnf: 0.0,
        rgnetto: 161.6,
        rgnettonf: 0.0,
        psum: 44.95,
        psumnf: 0.0,
        psumex: 42.02,
        psumexnf: 0.0,
        steuer7: 8.55,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 130.63,
        rbetragnf: 0.0,
        srgesamt: 129.68,
        srgesamtnf: 0.0,
        eksum: 57.08,
        eksumnf: 0.0,
        vkwert: 192.88,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-19',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 192.88,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -99.1,
        provproz: 26,
        skontproz: 0,
        nsum: 122.08,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1079528,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010833',
        rnr: 'R03/2023/010833',
        rtotal: 105.03,
        rgesamt: 127.92,
        rgesamtnf: 0.0,
        rgnetto: 119.56,
        rgnettonf: 0.0,
        psum: 25.57,
        psumnf: 0.0,
        psumex: 23.91,
        psumexnf: 0.0,
        steuer7: 6.88,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 105.03,
        rbetragnf: 0.0,
        srgesamt: 95.96,
        srgesamtnf: 0.0,
        eksum: 38.64,
        eksumnf: 0.0,
        vkwert: 152.91,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-26',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 83.66,
        avqnf: 0.0,
        avqtotal: 83.66,
        ca: false,
        geliefert: 132.93,
        bas: 19.98,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -62.55,
        provproz: 20,
        skontproz: 0,
        nsum: 98.15,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1080457,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010969',
        rnr: 'R03/2023/010969',
        rtotal: 109.03,
        rgesamt: 132.92,
        rgesamtnf: 0.0,
        rgnetto: 0.0,
        rgnettonf: 0.0,
        psum: 26.57,
        psumnf: 0.0,
        psumex: 24.85,
        psumexnf: 0.0,
        steuer7: 7.14,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 109.03,
        rbetragnf: 0.0,
        srgesamt: 99.7,
        srgesamtnf: 0.0,
        eksum: 45.14,
        eksumnf: 0.0,
        vkwert: 152.91,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-02',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 84.96,
        avqnf: 0.0,
        avqtotal: 84.96,
        ca: false,
        geliefert: 152.91,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -69.99,
        provproz: 20,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
      {
        _id: 1081258,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010970',
        rnr: 'R03/2023/010970',
        rtotal: 143.2,
        rgesamt: 189.89,
        rgesamtnf: 0.0,
        rgnetto: 177.47,
        rgnettonf: 0.0,
        psum: 49.37,
        psumnf: 0.0,
        psumex: 46.14,
        psumexnf: 0.0,
        steuer7: 9.37,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 143.2,
        rbetragnf: 0.0,
        srgesamt: 142.41,
        srgesamtnf: 0.0,
        eksum: 58.36,
        eksumnf: 0.0,
        vkwert: 216.87,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-09',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 87.56,
        avqnf: 0.0,
        avqtotal: 87.56,
        ca: false,
        geliefert: 216.87,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -104.5,
        provproz: 26,
        skontproz: 0,
        nsum: 133.83,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
      },
    ];
    ('');
  }
}
