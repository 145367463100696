import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, catchError, throwError } from 'rxjs';

export type HttpRequestState<T> = {
  isLoading: boolean;
  data?: T;
  error?: HttpErrorResponse | Error;
};

@Injectable()
export class MandantIdInterceptor implements HttpInterceptor {
  constructor(private authStorage: OAuthStorage) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const mandantId = this.getInitialMandantId();
    const modifiedReq = req.clone({
      headers: req.headers.set('X-Central-MandantId', mandantId.toString()),
    });

    return next.handle(modifiedReq);
  }

  private getInitialMandantId(): number {
    const mandantId = parseInt(sessionStorage.getItem('mandantId') || '', 10);

    // Check if mandantId is a number (since getItem can return null if the key does not exist)
    if (!isNaN(mandantId)) {
      return mandantId;
    } else {
      return 0;
    }
  }
}

@Injectable()
export class ErrorHandleInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error on HTTP', error);
        return throwError(() => error);
      })
    );
  }
}
