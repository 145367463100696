<h2 class="content-block">Willkommen</h2>
<div class="content-block">
  <div class="dx-card responsive-paddings">
    <p>Willkommen bei HBW Central ng</p>
    @if(isProduction === false) {
    <div class="alert">
      <div>
        <p>
          Sie befinden sich in der TESTUMGEBUNG! Daten werden nicht Produktiv
          gespeichert! Produktionsumgebung ist
          <a href="https://central.hbw-flower.app/" target="_blank">
            https://central.hbw-flower.app/
          </a>
        </p>
      </div>
    </div>
    }
  </div>
</div>
