import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-customer-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class PortalCustomerInfoComponent {
  dataInvoice: any;
  dataCustomer: any;

  constructor() {
    this.dataInvoice = {
      _id: 1081258,
      knr: 'J6173',
      kname: 'Fontaine Lydia           ',
      lnr: 'P03/2023/010970',
      rnr: 'R03/2023/010970',
      rtotal: 143.2,
      rgesamt: 189.89,
      rgesamtnf: 0.0,
      rgnetto: 177.47,
      rgnettonf: 0.0,
      psum: 49.37,
      psumnf: 0.0,
      psumex: 46.14,
      psumexnf: 0.0,
      steuer7: 9.37,
      steuer16: 0.0,
      skonto: 0.0,
      rbetrag: 143.2,
      rbetragnf: 0.0,
      srgesamt: 142.41,
      srgesamtnf: 0.0,
      eksum: 58.36,
      eksumnf: 0.0,
      vkwert: 216.87,
      vkwertnf: 0.0,
      mg: 66,
      bar: false,
      scheck: false,
      lastschr: true,
      ueberw: false,
      aral: false,
      nfr: false,
      bez: false,
      rest: 0.0,
      datum: '2023-09-09',
      rs: false,
      llzahlart: false,
      llknr: 0,
      lasterl: true,
      tanknr: '          ',
      rplz: '     ',
      zahlart: 'LA',
      avq: 87.56,
      avqnf: 0.0,
      avqtotal: 87.56,
      ca: false,
      geliefert: 216.87,
      bas: 0.0,
      geliefertn: 0.0,
      basn: 0.0,
      steuer: 0.0,
      nertrag: -104.5,
      provproz: 26,
      skontproz: 0,
      nsum: 133.83,
      nsumnf: 0.0,
      skonto7: 0.0,
      skonto16: 0.0,
      objnr: 16501,
      spausch: 2.68,
      spauschex: 2.5,
      ldatum: null,
      lkz: '          ',
      psumexsat: 0.0,
      rgnettosat: 0.0,
      provprozsa: 25,
      krgesamt: 0.0,
      kgeliefert: 0.0,
      kbas: 0.0,
      provproznf: 25,
    };

    this.dataCustomer = {
      _id: 3572,
      knr: 'J6173',
      debit: '11652',
      lekkerland: '          ',
      firma: 'JET-Station                                       ',
      vorname: '               ',
      kname: 'Fontaine Lydia                                    ',
      plz: '66793',
      ort: 'Saarwellingen                                     ',
      strasse: 'Vorstadter Str. 133                               ',
      telefon: '06838-92098   ',
      provi: 25,
      provinf: 0,
      provisat: 25,
      mg: '  ',
      tour: 66,
      nfk: ' ',
      tanknr: 'XJ00568      ',
      kontonr: 'DE22593501100003001781            ',
      blz: 'KRSADE55XXX',
      skonto: 0,
      zahlart: 'LA',
      zahlartnf: '  ',
      llndl: 0,
      fax: '92093          ',
      mgprov: false,
      mgprovnf: ' ',
      eindat: null,
      vlt: '   ',
      aenderung: '2023-06-24',
      objektnr: 16501,
      wt1: 'JA',
      wt2: 'IN',
      wt3: '  ',
      tel2: '                         ',
      mobil: '                         ',
      sp: true,
      spwert: 2.5,
      email: 'lydia-fontaine@t-online.de                        ',
      www: '                                                            ',
      bonus: 0.0,
      apartner: '                         ',
      bonustart: null,
      nl: 'SNH',
      oeffnung: '6-22                ',
      shopqm: 90,
      gesellsch: '                    ',
      user: '          ',
      new: false,
      changed: false,
      adm: 'SA-TOUR             ',
      hbwid: 3,
      hbwidnam: 'Krystian            ',
      lkz: 'D ',
      uid: '               ',
      vip: false,
      dbemerk:
        'nur SAMSTAGSbelieferung                                                                                                 ',
      fbemerk:
        '                                                                                                                        ',
      varfilter: 'JA',
      insammler: false,
      istsammler: false,
      sammlerknr: '     ',
      unter: 'HBW',
      staffel: true,
      nfkat: 'X',
    };
  }
}
