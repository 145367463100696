import { CommonModule } from '@angular/common';
import {
  DxCheckBoxModule,
  DxButtonModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxValidationGroupModule,
  DxTemplateModule,
  DxLookupModule,
} from 'devextreme-angular';
import { AuthService } from './../../shared/services/core/auth/auth.service';
import {
  Mandant,
  MandantenService,
} from './../../shared/services/mandanten.service';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mandant',
  standalone: true,
  imports: [
    DxCheckBoxModule,
    DxButtonModule,
    CommonModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxValidationGroupModule,
    DxTemplateModule,
    DxLookupModule,
  ],
  templateUrl: './mandant.component.html',
  styleUrl: './mandant.component.scss',
})
export class MandantComponent {
  mandant$!: Observable<Array<Mandant>>;
  selectedMandantId: number | null = null;

  constructor(
    private mandantService: MandantenService,
    private authService: AuthService,
    private router: Router
  ) {
    this.mandant$ = this.mandantService.Store$;
  }

  saveData(event: any) {
    const validationResult = event.validationGroup.validate();
    if (validationResult.isValid) {
      this.authService.MandantId = this.selectedMandantId!;

      if (this.authService.InitialRequestedUrl) {
        this.router.navigateByUrl(this.authService.InitialRequestedUrl);
        this.authService.InitialRequestedUrl = '';
        return;
      }
      this.router.navigate(['home']);
    }
  }
}
