import { MandantComponent } from './pages/mandant/mandant.component';
import { PortalOverviewComponent } from './pages/portal/overview/overview.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';

import {
  DxDataGridModule,
  DxFormModule,
  DxTabPanelModule,
  DxTemplateModule,
  DxMultiViewModule,
  DxListModule,
  DxChartModule,
  DxSchedulerModule,
  DxSpeedDialActionModule,
  DxBoxModule,
  DxButtonModule,
  DxDateBoxModule,
  DxDateRangeBoxModule,
  DxValidatorModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxButtonGroupModule,
  DxNumberBoxModule,
  DxSwitchModule,
  DxLookupModule,
  DxPopupModule,
  DxLoadPanelModule,
} from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';

import { PortalCustomerInfoComponent } from './pages/portal/customer/info/info.component';
import { PortalInvoiceListComponent } from './pages/portal/invoice/list/list.component';
import { AuthGuard } from './shared/services';

import { WeekDayPipe } from './shared/pipes/weekDayPipe';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/customers/customers.module').then(
        (m) => m.CustomersModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'dispo',
    loadChildren: () =>
      import('./pages/dispo/dispo.module').then((m) => m.DispoModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'portal',
    component: PortalOverviewComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'mandant',
    component: MandantComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'tours',
    loadChildren: () =>
      import('./pages/tours/tour.module').then((m) => m.TourModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'taxes',
    loadChildren: () =>
      import('./pages/tax/tax.module').then((m) => m.TaxModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'articles',
    loadChildren: () =>
      import('./pages/article/article.module').then((m) => m.ArticleModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'articlegroups',
    loadChildren: () =>
      import('./pages/articlegroup/articlegroup.module').then(
        (m) => m.ArticleGroupModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pricegroups',
    loadChildren: () =>
      import('./pages/pricegroups/pricegroups.module').then(
        (m) => m.PricegroupsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'driversoffice',
    loadChildren: () =>
      import('./pages/driversoffice/driversoffice.module').then(
        (m) => m.DriversOfficeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'drivers',
    loadChildren: () =>
      import('./pages/drivers/driver.module').then((m) => m.DriversModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('./pages/invoices/invoices.module').then((m) => m.InvoicesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'delivery',
    loadChildren: () =>
      import('./pages/delivery/delivery.module').then((m) => m.DeliveryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./pages/stats/stats.module').then((m) => m.StatsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'accounting',
    loadChildren: () =>
      import('./pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'invoicing',
    loadChildren: () =>
      import('./pages/invoicing/invoicing.routes').then(
        (m) => m.invoicingRoutes
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DxDataGridModule,
    DxFormModule,
    DxTabPanelModule,
    DxTemplateModule,
    DxMultiViewModule,
    DxListModule,
    DxChartModule,
    DxSchedulerModule,
    DxSpeedDialActionModule,
    DxBoxModule,
    DxButtonModule,
    BrowserModule,
    DxDateBoxModule,
    DxDateRangeBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxValidationSummaryModule,
    DxButtonGroupModule,
    DxNumberBoxModule,
    DxSwitchModule,
    DxLookupModule,
    DxSpeedDialActionModule,
    DxPopupModule,
    DxLoadPanelModule,
    WeekDayPipe,
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
  declarations: [
    HomeComponent,
    ProfileComponent,

    PortalCustomerInfoComponent,
    PortalInvoiceListComponent,
    PortalOverviewComponent,
  ],
})
export class AppRoutingModule {}
