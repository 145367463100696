import { Component } from '@angular/core';

@Component({
  selector: 'app-portal-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class PortalOverviewComponent {
  dataInvoice: any;
  dataCustomer: any;

  constructor() {
    this.dataInvoice = [
      {
        _id: 1093050,
        _gid: 'EFDE8F01-BC9E-4E08-B8EA-B382B964A446',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:04',
        _updated_at: '2023-12-04 13:16:04',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/013651',
        rnr: 'R03/2023/013651',
        rtotal: 109.06,
        rgesamt: 118.92,
        rgesamtnf: 14.99,
        rgnetto: 111.14,
        rgnettonf: 12.6,
        psum: 23.79,
        psumnf: 3.74,
        psumex: 22.23,
        psumexnf: 3.15,
        steuer7: 6.4,
        steuer16: 1.8,
        skonto: 0.0,
        rbetrag: 97.81,
        rbetragnf: 11.25,
        srgesamt: 89.2,
        srgesamtnf: 11.25,
        eksum: 90.04,
        eksumnf: 15.24,
        vkwert: 304.81,
        vkwertnf: 29.98,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-12-01',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: 'XJ00568   ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 39.01,
        avqnf: 50.0,
        avqtotal: 40.0,
        ca: false,
        geliefert: 304.81,
        bas: 0.0,
        geliefertn: 29.98,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -130.66,
        provproz: 20,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1092062,
        _gid: '3BF7864A-7156-4109-999D-E63DD7A10B34',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:03',
        _updated_at: '2023-12-04 13:16:03',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/013587',
        rnr: 'R03/2023/013587',
        rtotal: 102.61,
        rgesamt: 124.92,
        rgesamtnf: 0.0,
        rgnetto: 116.74,
        rgnettonf: 0.0,
        psum: 24.99,
        psumnf: 0.0,
        psumex: 23.35,
        psumexnf: 0.0,
        steuer7: 6.72,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 102.61,
        rbetragnf: 0.0,
        srgesamt: 93.69,
        srgesamtnf: 0.0,
        eksum: 95.83,
        eksumnf: 0.0,
        vkwert: 321.8,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-11-25',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: 'XJ00568   ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 38.82,
        avqnf: 0.0,
        avqtotal: 38.82,
        ca: false,
        geliefert: 321.8,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -119.18,
        provproz: 20,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1091355,
        _gid: '6CAECDEB-49C6-4FA3-B877-7AB33A06FC10',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:03',
        _updated_at: '2023-12-04 13:16:03',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/013521',
        rnr: 'R03/2023/013521',
        rtotal: 121.75,
        rgesamt: 160.9,
        rgesamtnf: 0.0,
        rgnetto: 150.38,
        rgnettonf: 0.0,
        psum: 41.83,
        psumnf: 0.0,
        psumex: 39.1,
        psumexnf: 0.0,
        steuer7: 7.97,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 121.75,
        rbetragnf: 0.0,
        srgesamt: 120.67,
        srgesamtnf: 0.0,
        eksum: 84.31,
        eksumnf: 0.0,
        vkwert: 277.83,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-11-18',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 57.91,
        avqnf: 0.0,
        avqtotal: 57.91,
        ca: false,
        geliefert: 277.83,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -123.41,
        provproz: 26,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1090386,
        _gid: 'B596735C-A885-44AB-82A5-C969295922A3',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:02',
        _updated_at: '2023-12-04 13:16:02',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011533',
        rnr: 'R03/2023/011533',
        rtotal: 115.1,
        rgesamt: 151.9,
        rgesamtnf: 0.0,
        rgnetto: 141.98,
        rgnettonf: 0.0,
        psum: 39.48,
        psumnf: 0.0,
        psumex: 36.91,
        psumexnf: 0.0,
        steuer7: 7.53,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 115.1,
        rbetragnf: 0.0,
        srgesamt: 113.96,
        srgesamtnf: 0.0,
        eksum: 87.09,
        eksumnf: 0.0,
        vkwert: 345.79,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-11-11',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 46.82,
        avqnf: 0.0,
        avqtotal: 46.82,
        ca: false,
        geliefert: 291.82,
        bas: 53.97,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -124.0,
        provproz: 26,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1088708,
        _gid: 'F93477FC-BD42-44DC-9AD1-8C3126A2DA4E',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:01',
        _updated_at: '2023-12-04 13:16:01',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011516',
        rnr: 'R03/2023/011516',
        rtotal: 206.8,
        rgesamt: 275.84,
        rgesamtnf: 0.0,
        rgnetto: 257.8,
        rgnettonf: 0.0,
        psum: 71.72,
        psumnf: 0.0,
        psumex: 67.03,
        psumexnf: 0.0,
        steuer7: 13.53,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 206.8,
        rbetragnf: 0.0,
        srgesamt: 206.9,
        srgesamtnf: 0.0,
        eksum: 139.15,
        eksumnf: 0.0,
        vkwert: 466.74,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-11-02',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 61.24,
        avqnf: 0.0,
        avqtotal: 61.24,
        ca: false,
        geliefert: 456.75,
        bas: 9.99,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -206.18,
        provproz: 26,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1088205,
        _gid: '87CA4174-D96B-4CBF-88E0-6EC5C4E6B4F2',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:01',
        _updated_at: '2023-12-04 13:16:01',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011451',
        rnr: 'R03/2023/011451',
        rtotal: 149.12,
        rgesamt: 197.87,
        rgesamtnf: 0.0,
        rgnetto: 184.95,
        rgnettonf: 0.0,
        psum: 51.43,
        psumnf: 0.0,
        psumex: 48.09,
        psumexnf: 0.0,
        steuer7: 9.76,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 149.12,
        rbetragnf: 0.0,
        srgesamt: 148.44,
        srgesamtnf: 0.0,
        eksum: 64.27,
        eksumnf: 0.0,
        vkwert: 272.82,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-10-28',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 76.19,
        avqnf: 0.0,
        avqtotal: 76.19,
        ca: false,
        geliefert: 212.88,
        bas: 59.94,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -112.36,
        provproz: 26,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1087403,
        _gid: '3DF316D3-A254-490B-BF34-5E3F48EAFBB5',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:16:00',
        _updated_at: '2023-12-04 13:16:00',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011381',
        rnr: 'R03/2023/011381',
        rtotal: 134.32,
        rgesamt: 177.89,
        rgesamtnf: 0.0,
        rgnetto: 166.26,
        rgnettonf: 0.0,
        psum: 46.25,
        psumnf: 0.0,
        psumex: 43.23,
        psumexnf: 0.0,
        steuer7: 8.79,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 134.32,
        rbetragnf: 0.0,
        srgesamt: 133.44,
        srgesamtnf: 0.0,
        eksum: 89.41,
        eksumnf: 0.0,
        vkwert: 292.8,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-10-21',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 76.39,
        avqnf: 0.0,
        avqtotal: 76.39,
        ca: false,
        geliefert: 292.8,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -132.64,
        provproz: 26,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1086334,
        _gid: '8ADAEAE4-78FD-4F0A-A8C1-BE8090ADF44C',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:59',
        _updated_at: '2023-12-04 13:15:59',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011312',
        rnr: 'R03/2023/011312',
        rtotal: 126.2,
        rgesamt: 166.9,
        rgesamtnf: 0.0,
        rgnetto: 156.0,
        rgnettonf: 0.0,
        psum: 43.38,
        psumnf: 0.0,
        psumex: 40.56,
        psumexnf: 0.0,
        steuer7: 8.26,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 126.2,
        rbetragnf: 0.0,
        srgesamt: 126.2,
        srgesamtnf: 0.0,
        eksum: 83.38,
        eksumnf: 0.0,
        vkwert: 277.84,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-10-14',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 60.07,
        avqnf: 0.0,
        avqtotal: 60.07,
        ca: false,
        geliefert: 277.84,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: 32.06,
        provproz: 26,
        skontproz: 0,
        nsum: 117.94,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1085405,
        _gid: 'DE22014A-600A-4D8D-BB0F-45CE3C2F801B',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:59',
        _updated_at: '2023-12-04 13:15:59',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011246',
        rnr: 'R03/2023/011246',
        rtotal: 197.96,
        rgesamt: 263.86,
        rgesamtnf: 0.0,
        rgnetto: 246.62,
        rgnettonf: 0.0,
        psum: 68.58,
        psumnf: 0.0,
        psumex: 64.12,
        psumexnf: 0.0,
        steuer7: 12.96,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 197.96,
        rbetragnf: 0.0,
        srgesamt: 197.89,
        srgesamtnf: 0.0,
        eksum: 79.7,
        eksumnf: 0.0,
        vkwert: 263.86,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-10-07',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 263.86,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -143.82,
        provproz: 26,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1084396,
        _gid: '210D2386-255C-4825-AB80-A3519ABD5E9C',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:58',
        _updated_at: '2023-12-04 13:15:58',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011178',
        rnr: 'R03/2023/011178',
        rtotal: 99.43,
        rgesamt: 120.93,
        rgesamtnf: 0.0,
        rgnetto: 113.02,
        rgnettonf: 0.0,
        psum: 24.18,
        psumnf: 0.0,
        psumex: 22.6,
        psumexnf: 0.0,
        steuer7: 6.51,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 99.43,
        rbetragnf: 0.0,
        srgesamt: 90.7,
        srgesamtnf: 0.0,
        eksum: 61.03,
        eksumnf: 0.0,
        vkwert: 203.89,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-30',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 59.31,
        avqnf: 0.0,
        avqtotal: 59.31,
        ca: false,
        geliefert: 203.89,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -83.63,
        provproz: 20,
        skontproz: 0,
        nsum: 92.92,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1083402,
        _gid: '24711228-1102-4E49-AE1B-FB5C939A7040',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:58',
        _updated_at: '2023-12-04 13:15:58',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011111',
        rnr: 'R03/2023/011111',
        rtotal: 118.6,
        rgesamt: 144.91,
        rgesamtnf: 0.0,
        rgnetto: 135.43,
        rgnettonf: 0.0,
        psum: 28.99,
        psumnf: 0.0,
        psumex: 27.09,
        psumexnf: 0.0,
        steuer7: 7.76,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 118.6,
        rbetragnf: 0.0,
        srgesamt: 108.68,
        srgesamtnf: 0.0,
        eksum: 44.69,
        eksumnf: 0.0,
        vkwert: 171.89,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-23',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 84.3,
        avqnf: 0.0,
        avqtotal: 84.3,
        ca: false,
        geliefert: 171.89,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -71.78,
        provproz: 20,
        skontproz: 0,
        nsum: 110.84,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1082385,
        _gid: 'B91DB8E9-E9DC-4469-AB21-72F47E7A00B2',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:57',
        _updated_at: '2023-12-04 13:15:57',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/011041',
        rnr: 'R03/2023/011041',
        rtotal: 143.94,
        rgesamt: 190.88,
        rgesamtnf: 0.0,
        rgnetto: 178.41,
        rgnettonf: 0.0,
        psum: 49.62,
        psumnf: 0.0,
        psumex: 46.39,
        psumexnf: 0.0,
        steuer7: 9.42,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 143.94,
        rbetragnf: 0.0,
        srgesamt: 143.19,
        srgesamtnf: 0.0,
        eksum: 57.69,
        eksumnf: 0.0,
        vkwert: 190.88,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-16',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 190.88,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -104.08,
        provproz: 26,
        skontproz: 0,
        nsum: 134.52,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1081258,
        _gid: '489113A8-B4E4-4267-B72C-A52F72BDD101',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:56',
        _updated_at: '2023-12-04 13:15:56',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010970',
        rnr: 'R03/2023/010970',
        rtotal: 143.2,
        rgesamt: 189.89,
        rgesamtnf: 0.0,
        rgnetto: 177.47,
        rgnettonf: 0.0,
        psum: 49.37,
        psumnf: 0.0,
        psumex: 46.14,
        psumexnf: 0.0,
        steuer7: 9.37,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 143.2,
        rbetragnf: 0.0,
        srgesamt: 142.41,
        srgesamtnf: 0.0,
        eksum: 58.36,
        eksumnf: 0.0,
        vkwert: 216.87,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-09',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 87.56,
        avqnf: 0.0,
        avqtotal: 87.56,
        ca: false,
        geliefert: 216.87,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -104.5,
        provproz: 26,
        skontproz: 0,
        nsum: 133.83,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1080457,
        _gid: 'D5E43D10-5810-47FB-BBB5-65C0F6774DFE',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:56',
        _updated_at: '2023-12-04 13:15:56',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010969',
        rnr: 'R03/2023/010969',
        rtotal: 109.03,
        rgesamt: 132.92,
        rgesamtnf: 0.0,
        rgnetto: 0.0,
        rgnettonf: 0.0,
        psum: 26.57,
        psumnf: 0.0,
        psumex: 24.85,
        psumexnf: 0.0,
        steuer7: 7.14,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 109.03,
        rbetragnf: 0.0,
        srgesamt: 99.7,
        srgesamtnf: 0.0,
        eksum: 45.14,
        eksumnf: 0.0,
        vkwert: 152.91,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-09-02',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 84.96,
        avqnf: 0.0,
        avqtotal: 84.96,
        ca: false,
        geliefert: 152.91,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -69.99,
        provproz: 20,
        skontproz: 0,
        nsum: 0.0,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1079528,
        _gid: '6BD21C0E-DE03-40A1-8A4E-6F88FD21546F',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:55',
        _updated_at: '2023-12-04 13:15:55',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010833',
        rnr: 'R03/2023/010833',
        rtotal: 105.03,
        rgesamt: 127.92,
        rgesamtnf: 0.0,
        rgnetto: 119.56,
        rgnettonf: 0.0,
        psum: 25.57,
        psumnf: 0.0,
        psumex: 23.91,
        psumexnf: 0.0,
        steuer7: 6.88,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 105.03,
        rbetragnf: 0.0,
        srgesamt: 95.96,
        srgesamtnf: 0.0,
        eksum: 38.64,
        eksumnf: 0.0,
        vkwert: 152.91,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-26',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 83.66,
        avqnf: 0.0,
        avqtotal: 83.66,
        ca: false,
        geliefert: 132.93,
        bas: 19.98,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -62.55,
        provproz: 20,
        skontproz: 0,
        nsum: 98.15,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1078508,
        _gid: '6C7F2ED4-C26D-4DCF-8E9C-33DF5C166FFA',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:55',
        _updated_at: '2023-12-04 13:15:55',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010765',
        rnr: 'R03/2023/010765',
        rtotal: 130.63,
        rgesamt: 172.9,
        rgesamtnf: 0.0,
        rgnetto: 161.6,
        rgnettonf: 0.0,
        psum: 44.95,
        psumnf: 0.0,
        psumex: 42.02,
        psumexnf: 0.0,
        steuer7: 8.55,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 130.63,
        rbetragnf: 0.0,
        srgesamt: 129.68,
        srgesamtnf: 0.0,
        eksum: 57.08,
        eksumnf: 0.0,
        vkwert: 192.88,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-19',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 192.88,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -99.1,
        provproz: 26,
        skontproz: 0,
        nsum: 122.08,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1077576,
        _gid: 'F8D7D4BB-158A-4AB6-83B0-94FFF48D25F5',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:54',
        _updated_at: '2023-12-04 13:15:54',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010686',
        rnr: 'R03/2023/010686',
        rtotal: 109.03,
        rgesamt: 132.93,
        rgesamtnf: 0.0,
        rgnetto: 124.24,
        rgnettonf: 0.0,
        psum: 26.58,
        psumnf: 0.0,
        psumex: 24.85,
        psumexnf: 0.0,
        steuer7: 7.14,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 109.03,
        rbetragnf: 0.0,
        srgesamt: 99.69,
        srgesamtnf: 0.0,
        eksum: 38.64,
        eksumnf: 0.0,
        vkwert: 132.93,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-12',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 132.93,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -63.49,
        provproz: 20,
        skontproz: 0,
        nsum: 101.89,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1076251,
        _gid: 'A1E34551-CE00-4E67-AF16-DE129CDA18E7',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:53',
        _updated_at: '2023-12-04 13:15:53',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010617',
        rnr: 'R03/2023/010617',
        rtotal: 83.0,
        rgesamt: 97.95,
        rgesamtnf: 0.0,
        rgnetto: 91.55,
        rgnettonf: 0.0,
        psum: 17.63,
        psumnf: 0.0,
        psumex: 16.48,
        psumexnf: 0.0,
        steuer7: 5.43,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 83.0,
        rbetragnf: 0.0,
        srgesamt: 73.46,
        srgesamtnf: 0.0,
        eksum: 38.5,
        eksumnf: 0.0,
        vkwert: 132.93,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-08-05',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 73.69,
        avqnf: 0.0,
        avqtotal: 73.69,
        ca: false,
        geliefert: 132.93,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -54.98,
        provproz: 18,
        skontproz: 0,
        nsum: 77.57,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1075503,
        _gid: '14E23AC0-EC0D-440B-8A18-C34D7F6C4C76',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:53',
        _updated_at: '2023-12-04 13:15:53',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010547',
        rnr: 'R03/2023/010547',
        rtotal: 123.24,
        rgesamt: 162.9,
        rgesamtnf: 0.0,
        rgnetto: 152.26,
        rgnettonf: 0.0,
        psum: 42.34,
        psumnf: 0.0,
        psumex: 39.59,
        psumexnf: 0.0,
        steuer7: 8.07,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 123.24,
        rbetragnf: 0.0,
        srgesamt: 122.19,
        srgesamtnf: 0.0,
        eksum: 48.25,
        eksumnf: 0.0,
        vkwert: 162.9,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-29',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 100.0,
        avqnf: 0.0,
        avqtotal: 100.0,
        ca: false,
        geliefert: 162.9,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -87.84,
        provproz: 26,
        skontproz: 0,
        nsum: 115.17,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
      {
        _id: 1074598,
        _gid: '50F84C0E-C9AD-431E-ABC0-6DC5BC973CC2',
        _mandant_id: 1,
        _created_at: '2023-12-04 13:15:52',
        _updated_at: '2023-12-04 13:15:52',
        _version: 1,
        _created_by: null,
        _updated_by: null,
        knr: 'J6173',
        kname: 'Fontaine Lydia           ',
        lnr: 'P03/2023/010480',
        rnr: 'R03/2023/010480',
        rtotal: 123.99,
        rgesamt: 163.92,
        rgesamtnf: 0.0,
        rgnetto: 153.2,
        rgnettonf: 0.0,
        psum: 42.61,
        psumnf: 0.0,
        psumex: 39.83,
        psumexnf: 0.0,
        steuer7: 8.12,
        steuer16: 0.0,
        skonto: 0.0,
        rbetrag: 123.99,
        rbetragnf: 0.0,
        srgesamt: 122.93,
        srgesamtnf: 0.0,
        eksum: 51.41,
        eksumnf: 0.0,
        vkwert: 178.91,
        vkwertnf: 0.0,
        mg: 66,
        bar: false,
        scheck: false,
        lastschr: true,
        ueberw: false,
        aral: false,
        nfr: false,
        bez: false,
        rest: 0.0,
        datum: '2023-07-22',
        rs: false,
        llzahlart: false,
        llknr: 0,
        lasterl: true,
        tanknr: '          ',
        rplz: '     ',
        zahlart: 'LA',
        avq: 91.62,
        avqnf: 0.0,
        avqtotal: 91.62,
        ca: false,
        geliefert: 178.91,
        bas: 0.0,
        geliefertn: 0.0,
        basn: 0.0,
        steuer: 0.0,
        nertrag: -91.24,
        provproz: 26,
        skontproz: 0,
        nsum: 115.87,
        nsumnf: 0.0,
        skonto7: 0.0,
        skonto16: 0.0,
        objnr: 16501,
        spausch: 2.68,
        spauschex: 2.5,
        ldatum: null,
        lkz: '          ',
        psumexsat: 0.0,
        rgnettosat: 0.0,
        provprozsa: 25,
        krgesamt: 0.0,
        kgeliefert: 0.0,
        kbas: 0.0,
        provproznf: 25,
        liefer_id: null,
        kunden_id: null,
        tour_id: null,
        steuer_id: null,
        zahlart_id: null,
        servicepauschale_id: null,
        provision_id: null,
        objekt_id: null,
      },
    ];

    this.dataCustomer = {
      _id: 3572,
      knr: 'J6173',
      debit: '11652',
      lekkerland: '          ',
      firma: 'JET-Station                                       ',
      vorname: '               ',
      kname: 'Fontaine Lydia                                    ',
      plz: '66793',
      ort: 'Saarwellingen                                     ',
      strasse: 'Vorstadter Str. 133                               ',
      telefon: '06838-92098   ',
      provi: 25,
      provinf: 0,
      provisat: 25,
      mg: '  ',
      tour: 66,
      nfk: ' ',
      tanknr: 'XJ00568      ',
      kontonr: 'DE22593501100003001781            ',
      blz: 'KRSADE55XXX',
      skonto: 0,
      zahlart: 'LA',
      zahlartnf: '  ',
      llndl: 0,
      fax: '92093          ',
      mgprov: false,
      mgprovnf: ' ',
      eindat: null,
      vlt: '   ',
      aenderung: '2023-06-24',
      objektnr: 16501,
      wt1: 'JA',
      wt2: 'IN',
      wt3: '  ',
      tel2: '                         ',
      mobil: '                         ',
      sp: true,
      spwert: 2.5,
      email: 'lydia-fontaine@t-online.de                        ',
      www: '                                                            ',
      bonus: 0.0,
      apartner: '                         ',
      bonustart: null,
      nl: 'SNH',
      oeffnung: '6-22                ',
      shopqm: 90,
      gesellsch: '                    ',
      user: '          ',
      new: false,
      changed: false,
      adm: 'SA-TOUR             ',
      hbwid: 3,
      hbwidnam: 'Krystian            ',
      lkz: 'D ',
      uid: '               ',
      vip: false,
      dbemerk:
        'nur SAMSTAGSbelieferung                                                                                                 ',
      fbemerk:
        '                                                                                                                        ',
      varfilter: 'JA',
      insammler: false,
      istsammler: false,
      sammlerknr: '     ',
      unter: 'HBW',
      staffel: true,
      nfkat: 'X',
    };
  }
}
