import { authCodeFlowConfig } from './config/authConfig';
import { Component, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import deMessages from 'devextreme/localization/messages/de.json';
import { locale, loadMessages } from 'devextreme/localization';

import { OAuthEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import config from 'devextreme/core/config';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isAuthenticated$: Observable<boolean> =
    this.authService.isAuthenticated$;

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    readonly oAuthService: OAuthService,
    readonly oAuthStorage: OAuthStorage,
    private readonly titleService: Title
  ) {
    loadMessages(deMessages);
    locale('de-de');

    config({
      defaultCurrency: 'EUR',
      defaultUseCurrencyAccountingStyle: false,
    });

    this.titleService.setTitle(this.appInfo.title);
    //this.SetupoAuthService();
  }

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ');
  }

  private SetupoAuthService() {
    this.oAuthService.configure(authCodeFlowConfig);
    this.oAuthService.setupAutomaticSilentRefresh();

    this.oAuthService.events.subscribe((event: OAuthEvent) => {
      console.log(event.type, this.oAuthStorage.getItem('access_token'));

      if (event.type === 'token_received') {
        console.debug('logged in');
      }

      if (event.type === 'discovery_document_loaded') {
        // Discovery Document loaded
      }
    });

    const url =
      'https://login.microsoftonline.com/a8e1f3ae-ad29-4cc5-b1cc-1ee196b51caa/v2.0/.well-known/openid-configuration';

    // The convenience method mentioned in the docs (loadDiscoveryDocumentAndLogin) won't work
    // since we need a way to modify the token endpoint
    this.oAuthService
      .loadDiscoveryDocument(url)
      .then(() => {
        return this.oAuthService.tryLoginCodeFlow();
      })
      .then(() => {
        if (!this.oAuthService.hasValidAccessToken()) {
          this.oAuthService.initCodeFlow();
        }
      })
      .catch(() => {
        //this.oAuthService.initCodeFlow();
      });
  }
}
